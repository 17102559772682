//apis
export const API_URL = import.meta.env.VITE_BACKEND_BASE_URL
export const API_URL_V2 = import.meta.env.VITE_BACKEND_BASE_URL_V2

export const BACK_URL = `${API_URL}/backend`
export const BACK_URL_V2 = `${API_URL_V2}/backend`
export const BACK_CHAT_URL = `${API_URL}/chat`
export const BACK_WHATSAPP_URL = `${API_URL}/whatsapp`

export const CHAT_URL = import.meta.env.VITE_CHAT_BASE_URL

// redirect
export const APP_BACKEND_URL = import.meta.env.VITE_BACKEND_URL
export const REQUEST_LINK_URL = `${APP_BACKEND_URL}/editar_solicitud`
