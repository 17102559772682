import { defineStore } from 'pinia'
import { useTaskStore } from './tasks'
import { useAuthStore } from './auth'
import { ref } from 'vue'
import type { Task } from '../services/tasks'

export interface TaskSocketMessage {
  model_name: string
  action_type: ActionType
  model_data: Task
}

enum ActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export const useTaskConnectionStore = defineStore('task-socket-connection', () => {
  const isConnected = ref(false)
  const MAX_ATTEMP = 3
  const retry = ref(0)

  function connect() {
    const URL = import.meta.env.VITE_SOCKET_TASK_URL as string | undefined

    if (!URL) {
      throw new Error('Missing VITE_SOCKET_URL in .env')
    }

    const socketTask = new WebSocket(URL)

    socketTask.onopen = () => {
      console.log('Socket is open')
      isConnected.value = true
    }

    socketTask.onclose = (e) => {
      isConnected.value = false
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      setTimeout(() => {
        if (retry.value < MAX_ATTEMP) {
          retry.value++
          connect()
        } else {
          console.log('Se ha alcanzado el máximo número de intentos de reconexión')
        }
      }, 1000)
    }

    socketTask.onmessage = (message: MessageEvent) => {
      const parsedMessage = JSON.parse(message.data)
      if (parsedMessage) {
        handleSocketMessage(parsedMessage)
      }
    }

    socketTask.onerror = (error: any) => {
      console.error('Socket encountered error: ', error.message, 'Closing socket')
      socketTask.close()
    }
  }

  function handleSocketMessage(message: TaskSocketMessage) {
    console.log('Received message:', message)
    const taskStore = useTaskStore()
    const authStore = useAuthStore()
    if (message.model_data?.operator === authStore.user?.id) {
      switch (message.action_type) {
        case ActionType.CREATE:
        case ActionType.UPDATE:
          taskStore.upsertTask({ task: message.model_data })
          break
        case ActionType.DELETE:
          taskStore.deleteTask(message.model_data)
          break
        default:
          break
      }
    }
  }

  return { connect, isConnected }
})
